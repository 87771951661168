.cnh-digital__modal {
	max-width: unset !important;
	padding-bottom: 0;
	top: 0;
	width: auto !important;
}

.cnh-digital__modal .ant-modal-body {
	padding: 20px !important;
}

.cnh-digital__modal .ant-modal-body div {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cnh-digital__modal .ant-modal-body button {
	margin-top: 20px;
}

.modal-confirm-procurador .ant-modal-content .ant-modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 0px 10px 0px;
}

.modal-confirm-procurador .modal-confirm-procurador__button-prosseguir {
	margin-top: 15px;
}

.modal-confirm-procurador .modal-confirm-procurador__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid black;
	padding: 20px 110px;
}

.modal-ficha-aluno-telefones .ant-modal-close-x {
	width: 20px;
	height: 20px;
	display: flex;
	margin: 5px;
}
